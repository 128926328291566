import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Container, Form, Button, Accordion, Row, Col, Pagination } from 'react-bootstrap';
import Select from 'react-select';  // Import react-select

function LeadsList() {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    main_heating_control: [],
    property_type: [],
    floor_description: [],
    built_form: [],
    windows_description: [],
    total_floor_area: [],
    roof_description: [],
    posttown: [],
    current_energy_rating: [],
    potential_energy_rating: [],
    main_heating_controls: [],
    walls_description: [],
    hotwater_description: [],
    tenure: [],
    main_fuel: [],
    lodgement_date: '',
    postcode: '',
    radius: '',
    lead_status: [],
    lead_source: []
  });

  const [distinctValues, setDistinctValues] = useState({
    property_type: [],
    main_heating_control: [],
    floor_description: [],
    built_form: [],
    windows_description: [],
    total_floor_area: [],
    roof_description: [],
    posttown: [],
    current_energy_rating: [],
    potential_energy_rating: [],
    main_heating_controls: [],
    walls_description: [],
    hotwater_description: [],
    tenure: [],
    main_fuel: [],
    lead_source: [],
    lead_status: ['Open', 'Closed'],
  });

  const limit = 50;

  useEffect(() => {
    fetchLeads();
    fetchDistinctValues('property_type');
    fetchDistinctValues('main_heating_control');
    fetchDistinctValues('floor_description');
    fetchDistinctValues('built_form');
    fetchDistinctValues('windows_description');
    fetchDistinctValues('total_floor_area');
    fetchDistinctValues('roof_description');
    fetchDistinctValues('posttown');
    fetchDistinctValues('current_energy_rating');
    fetchDistinctValues('potential_energy_rating');
    fetchDistinctValues('main_heating_controls');
    fetchDistinctValues('walls_description');
    fetchDistinctValues('hotwater_description');
    fetchDistinctValues('tenure');
    fetchDistinctValues('main_fuel');
    fetchDistinctValues('lead_source');
    fetchDistinctValues('lead_status');
  }, [currentPage]);

  const fetchLeads = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/leads`, {
        params: {
          ...filters,
          page: currentPage,
          limit
        }
      });
      setLeads(response.data.leads);
      setTotalLeads(response.data.totalLeads);
    } catch (error) {
      console.error('Error fetching leads:', error.message);
    }
  };

  const fetchDistinctValues = async (field) => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      const response = await axios.get(`${apiBaseUrl}/api/epc/distinct/${field}`);
      setDistinctValues(prevState => ({
        ...prevState,
        [field]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching distinct values for ${field}:`, error.message);
    }
  };

  const handleMultiSelectChange = (selectedOptions, actionMeta) => {
    const { name } = actionMeta;
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFilters({
      ...filters,
      [name]: values
    });
  };

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchLeads();
  };

  const handleReset = () => {
    setFilters({
      main_heating_control: [],
      property_type: [],
      floor_description: [],
      built_form: [],
      windows_description: [],
      total_floor_area: [],
      roof_description: [],
      posttown: [],
      current_energy_rating: [],
      potential_energy_rating: [],
      main_heating_controls: [],
      walls_description: [],
      hotwater_description: [],
      tenure: [],
      main_fuel: [],
      lodgement_date: '',
      postcode: '',
      radius: '',
      lead_status: [],
      lead_source: []
    });
    fetchLeads();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <h1 className="my-4">All Leads</h1>

      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter Leads</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Property Type</Form.Label>
                    <Select
                      name="property_type"
                      isMulti
                      options={distinctValues.property_type.map((value) => ({ value, label: value }))}
                      value={filters.property_type.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Main Heating Control</Form.Label>
                    <Select
                      name="main_heating_control"
                      isMulti
                      options={distinctValues.main_heating_control.map((value) => ({ value, label: value }))}
                      value={filters.main_heating_control.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Floor Description</Form.Label>
                    <Select
                      name="floor_description"
                      isMulti
                      options={distinctValues.floor_description.map((value) => ({ value, label: value }))}
                      value={filters.floor_description.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Built Form</Form.Label>
                    <Select
                      name="built_form"
                      isMulti
                      options={distinctValues.built_form.map((value) => ({ value, label: value }))}
                      value={filters.built_form.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Windows Description</Form.Label>
                    <Select
                      name="windows_description"
                      isMulti
                      options={distinctValues.windows_description.map((value) => ({ value, label: value }))}
                      value={filters.windows_description.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Total Floor Area</Form.Label>
                    <Select
                      name="total_floor_area"
                      isMulti
                      options={distinctValues.total_floor_area.map((value) => ({ value, label: value }))}
                      value={filters.total_floor_area.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Roof Description</Form.Label>
                    <Select
                      name="roof_description"
                      isMulti
                      options={distinctValues.roof_description.map((value) => ({ value, label: value }))}
                      value={filters.roof_description.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Post Town</Form.Label>
                    <Select
                      name="posttown"
                      isMulti
                      options={distinctValues.posttown.map((value) => ({ value, label: value }))}
                      value={filters.posttown.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Current Energy Rating</Form.Label>
                    <Select
                      name="current_energy_rating"
                      isMulti
                      options={distinctValues.current_energy_rating.map((value) => ({ value, label: value }))}
                      value={filters.current_energy_rating.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Potential Energy Rating</Form.Label>
                    <Select
                      name="potential_energy_rating"
                      isMulti
                      options={distinctValues.potential_energy_rating.map((value) => ({ value, label: value }))}
                      value={filters.potential_energy_rating.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Main Heating Controls</Form.Label>
                    <Select
                      name="main_heating_controls"
                      isMulti
                      options={distinctValues.main_heating_controls.map((value) => ({ value, label: value }))}
                      value={filters.main_heating_controls.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Walls Description</Form.Label>
                    <Select
                      name="walls_description"
                      isMulti
                      options={distinctValues.walls_description.map((value) => ({ value, label: value }))}
                      value={filters.walls_description.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Hot Water Description</Form.Label>
                    <Select
                      name="hotwater_description"
                      isMulti
                      options={distinctValues.hotwater_description.map((value) => ({ value, label: value }))}
                      value={filters.hotwater_description.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Tenure</Form.Label>
                    <Select
                      name="tenure"
                      isMulti
                      options={distinctValues.tenure.map((value) => ({ value, label: value }))}
                      value={filters.tenure.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Main Fuel</Form.Label>
                    <Select
                      name="main_fuel"
                      isMulti
                      options={distinctValues.main_fuel.map((value) => ({ value, label: value }))}
                      value={filters.main_fuel.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Lead Status</Form.Label>
                    <Select
                      name="lead_status"
                      isMulti
                      options={distinctValues.lead_status.map((value) => ({ value, label: value }))}
                      value={filters.lead_status.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Lead Source</Form.Label>
                    <Select
                      name="lead_source"
                      isMulti
                      options={distinctValues.lead_source.map((value) => ({ value, label: value }))}
                      value={filters.lead_source.map((value) => ({ value, label: value }))}
                      onChange={handleMultiSelectChange}
                    />
                  </Form.Group>

                  {/* Additional filters for postcode and radius */}
                  <Form.Group>
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                      type="text"
                      name="postcode"
                      placeholder="Enter postcode"
                      value={filters.postcode}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Radius (miles)</Form.Label>
                    <Form.Control
                      type="number"
                      name="radius"
                      placeholder="Enter radius"
                      value={filters.radius}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button className="mt-3" onClick={handleSearch}>Search</Button>
              <Button variant="secondary" className="mt-3 ms-2" onClick={handleReset}>Reset</Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Leads Table */}
      <div className="table-responsive mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Lead Status</th>
              <th>Lead Source</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Housing Status</th>
              <th>Benefits</th>
              <th>Current Situation</th>
              <th>Gas Boiler</th>
              <th>Boiler Installation Year</th>
              <th>Entry Date</th>
              <th>Created At</th>
              <th>Floor Height</th>
              <th>Construction Age Band</th>
              <th>Potential Energy Rating</th>
              <th>Address3</th>
              <th>Main Heat Control Description</th>
              <th>Property Type</th>
              <th>Local Authority Label</th>
              <th>Mechanical Ventilation</th>
              <th>County</th>
              <th>Postcode</th>
              <th>Solar Water Heating Flag</th>
              <th>Constituency</th>
              <th>Floor Description</th>
              <th>Local Authority</th>
              <th>Built Form</th>
              <th>Windows Description</th>
              <th>Address1</th>
              <th>Constituency Label</th>
              <th>Total Floor Area</th>
              <th>Roof Description</th>
              <th>Number of Habitable Rooms</th>
              <th>Address2</th>
              <th>Post Town</th>
              <th>Main Fuel</th>
              <th>Main Heating Controls</th>
              <th>Flat Top Storey</th>
              <th>Current Energy Rating</th>
              <th>Second Heat Description</th>
              <th>Walls Environmental Efficiency</th>
              <th>Transaction Type</th>
              <th>Main Heat Description</th>
              <th>Lodgement Date</th>
              <th>Tenure</th>
              <th>Walls Description</th>
              <th>Hot Water Description</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead._id}>
                <td>
                  <Link to={`/lead/${lead._id}`}>
                    {lead.firstname}
                  </Link>
                </td>
                <td>{lead.surname}</td>
                <td>{lead.lead_status}</td>
                <td>{lead.lead_source}</td>
                <td>{lead.email}</td>
                <td>{lead.phone}</td>
                <td>{lead.address.line1}, {lead.address.postcode}</td>
                <td>{lead.housing_status}</td>
                <td>{lead.benefits.join(', ')}</td>
                <td>{lead.current_situation}</td>
                <td>{lead.gas_boiler ? 'Yes' : 'No'}</td>
                <td>{lead.boiler_installation_year}</td>
                <td>{new Date(lead.entry_date).toLocaleDateString()}</td>
                <td>{new Date(lead.createdAt).toLocaleDateString()}</td>
                <td>{lead.floor_height}</td>
                <td>{lead.construction_age_band}</td>
                <td>{lead.potential_energy_rating}</td>
                <td>{lead.address3}</td>
                <td>{lead.mainheatcont_description}</td>
                <td>{lead.property_type}</td>
                <td>{lead.local_authority_label}</td>
                <td>{lead.mechanical_ventilation}</td>
                <td>{lead.county}</td>
                <td>{lead.postcode}</td>
                <td>{lead.solar_water_heating_flag ? 'Yes' : 'No'}</td>
                <td>{lead.constituency}</td>
                <td>{lead.floor_description}</td>
                <td>{lead.local_authority}</td>
                <td>{lead.built_form}</td>
                <td>{lead.windows_description}</td>
                <td>{lead.address1}</td>
                <td>{lead.constituency_label}</td>
                <td>{lead.total_floor_area}</td>
                <td>{lead.roof_description}</td>
                <td>{lead.number_habitable_rooms}</td>
                <td>{lead.address2}</td>
                <td>{lead.posttown}</td>
                <td>{lead.main_fuel}</td>
                <td>{lead.main_heating_controls}</td>
                <td>{lead.flat_top_storey ? 'Yes' : 'No'}</td>
                <td>{lead.current_energy_rating}</td>
                <td>{lead.secondheat_description}</td>
                <td>{lead.walls_env_eff}</td>
                <td>{lead.transaction_type}</td>
                <td>{lead.mainheat_description}</td>
                <td>{lead.lodgement_date}</td>
                <td>{lead.tenure}</td>
                <td>{lead.walls_description}</td>
                <td>{lead.hotwater_description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Pagination className="mt-4">
        {Array.from({ length: Math.ceil(totalLeads / limit) }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
}

export default LeadsList;
